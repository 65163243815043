<template>
  <CRow>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <YardModal
      :modal.sync="modal"
      :yardId="yardId"
      :yardItem="yardItem"
      :edit="edit"
      :title="titleModal"
      @submited="handleSubmit"
      @close="closeModal"
    />
    <CCol sm="12">
            <CRow>
              <CCol sm="12" class="d-flex justify-content-end">
                <CButton
                  color="add"
                  class="d-flex align-items-center"
                  v-c-tooltip="{
                    content: $t('label.yard'),
                    placement: 'top-end'
                  }"
                  @click="toggleAdd"
                >
                  <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
                </CButton>
              </CCol>
              <CCol col="12" xl="12">
                <dataTableExtended 
                  class="align-center-row-datatable" 
                  hover 
                  sorter 
                  small 
                  column-filter
                  :table-filter="tableText.tableFilterText" 
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items="formatedItems" 
                  :fields="fields" 
                  :noItemsView="tableText.noItemsViewText"
                  :items-per-page="tableText.itemsPerPage" 
                  pagination 
                  >
                  <template #Status="{item}">
                    <td class="center-cell text-center">
                      <div>
                        <CBadge :color="getBadge(item.Status)">
                          {{$t('label.'+item.Status)}}
                        </CBadge>
                      </div>
                    </td>
                  </template>
                  <template #acciones="{ item }">
                    <td class="text-center">
                        <CButton
                          color="edit"
                          size="sm"
                          square
                          class="mr-1"
                          v-c-tooltip="{
                            content: $t('label.edit')+' '+$t('label.yard'),
                            placement: 'top-end'
                          }"
                          @click="toggleEditYard(item)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                        <CButton
                          color="watch"
                          square
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{
                            content: $t('label.toView') + ' '+$t('label.yardDetails'),
                            placement: 'top-end'
                          }"
                          @click="viewYard(item)"
                        >
                          <CIcon name="eye" />
                        </CButton>
                    </td>
                  </template>
                </dataTableExtended>
              </CCol>
            </CRow>
    </CCol>
  </CRow>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import { tableTextTranslatedHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import YardModal from './yard-modal';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

//data
function data() {
  return {
    Loading: false,
    items: [],
    modal: false,
    edit: false,
    yardId: '',
    yardItem: {},
    titleModal: ''
  }
}

//Method
function tableText() {
  return tableTextTranslatedHelpers(this);
}

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.getBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.items = listado;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function toggleAdd() {
  this.modal = true;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.yard');
}

function toggleEditYard(item) {
  this.yardId = item.YardId;
  this.yardItem = item;
  this.edit = true;
  this.modal = true;
  let title = this.yardItem.YardName;
  this.titleModal= this.$t('label.edit')+' '+this.$t('label.yard')+': '+title
}

function closeModal(){
  this.modal = false;
  this.edit = false;
  this.title = '';
}

function viewYard(item){
  this.$store.state.yard.yardSelected = item;
  this.$store.state.yard.collapse = true;
}

function handleSubmit() {
  this.getYards();
}

//computed
function fields() {
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left;', filter: false },
    { key: 'YardName', label: this.$t('label.yard'), _style: 'width:25%;',_classes:'text-center'},
    { key: 'YardAlias', label: this.$t('label.alias'), _style: 'width:25%;',_classes:'text-center'},
    { key: 'SquareMeters', label: 'AREA (M2)', _style: 'width:15%;',_classes:'text-center' },
    { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:10%;', _classes: 'text-uppercase text-center' },
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:10%;', _classes: "text-center" },
    { key: 'Status', label: this.$t('label.status'), _style: 'width:10%; text-align:center;', _classes: "text-center" },
    {
      key: 'acciones',
      label: '',
      _style: 'min-width:90px;',
      sorter: false,
      filter: false
    },
  ];
}

function formatedItems() {
  if(this.items == []){
    return this.items;
  }
  return this.items.map((item) => {
    return {
      ...item,
      Nro: item.Nro,
      YardName: item.YardName ?? 'N/A',
      YardAlias: item.YardAlias ?? 'N/A',
      UltimoUsuario: item.TransaLogin ? item.TransaLogin : "N/A",
      SquareMeters: item.SquareMeters ? formatMilDecimal(item.SquareMeters?.toFixed(2)) : 'N/A',
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
      _classes: item.Status === "INACTIVO" ? "table-danger" : "",
    };
  });
}

function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}
//watch
export default {
  name: 'yard-index',
  mixins:[GeneralMixin],
  components: {
    YardModal,
  },
  data,
  mounted() {
    this.getYards();
  },
  methods: {
    getYards,
    toggleEditYard, 
    toggleAdd,
    closeModal,
    viewYard,
    handleSubmit,
  },
  computed: {
    formatedItems,
    tableText,
    fields,
    getBranchId,
    ...mapState({
      collapse: state => state.yard.collapse,
      branch: (state) => state.auth.branch,
      tabIndex: state => state.yard.masterOfYardsTabIndex,
      itemsYard: state => state.yard.itemsYard
    })
  },
  watch: {
    tabIndex:function (val){
      if(val == 0){
        this.items = [];
        this.getYards();
      }
    },
  }
}
</script>

<style scoped>
.center-cell {
  vertical-align: middle;
}
</style>